import { DateTime } from 'luxon'
import { User } from '../types'

namespace ApiErrors {
  export enum Code {
    Forbidden = 'FORBIDDEN',
    Unknown = 'UNKNOWN',
  }

  export type ErrorModel = {
    status: number
    code: Code
    messages: string[]
  }
}

namespace ApiModels {
  export type Timestamps = {
    createdAt: DateTime
    updatedAt: DateTime
  }

  export type TokenGrant = {
    accessToken: string
    expiresAt: DateTime
  }

  export type AdminInfo = {
    isAdmin: boolean
    capabilities: Array<User.AdminCapability>
  }

  export type UserDetails = {
    id: string
    email: string
    name: string
    adminInfo: AdminInfo
    timestamps: Timestamps
  }

  export type LoginResponse = {
    user: UserDetails
    token: TokenGrant
  }
}

export { ApiErrors, ApiModels }
