import { defineStore } from 'pinia'
import { User } from './types'
import { QweebiApi } from './api/qweebi-api'
import { ApiErrors } from './api/types'
import { useSessionStorage } from '@vueuse/core'

type LoginActionResponse =
  | {
      successful: true
    }
  | {
      successful: false
      error: ApiErrors.ErrorModel
    }

const useUserStore = defineStore('user', {
  state: () => {
    return useSessionStorage('qweebi-admin-store', {
      userName: 'Vasili Savitski',
      email: 'vasili@gmail.com',
      memberSince: '8/12/2020',
      pfp: 'https://picsum.photos/id/22/200/300',
      is2FAEnabled: false,
      info: null as User.Info | null,
      accessToken: null as User.AccessToken | null,
    })
  },

  getters: {
    isLoggedIn: (state) => state.info !== null && state.accessToken !== null,
  },

  actions: {
    toggle2FA() {
      this.is2FAEnabled = !this.is2FAEnabled
    },

    changeUserName(userName: string) {
      this.userName = userName
    },

    storeLoggedInUser(info: User.Info, accessToken: User.AccessToken) {
      this.info = info
      this.accessToken = accessToken
      QweebiApi.authorize(accessToken.token)
    },

    async login(email: string, password: string): Promise<LoginActionResponse> {
      const response = await QweebiApi.loginUser(email, password)

      if (QweebiApi.isSuccess(response)) {
        const { user, token } = response.payload

        if (user.adminInfo.isAdmin) {
          this.storeLoggedInUser(
            {
              id: user.id,
              name: user.name,
              email: user.email,
              adminInfo: user.adminInfo,
            },
            {
              token: token.accessToken,
              expiry: token.expiresAt,
            },
          )

          return {
            successful: true,
          }
        } else {
          return {
            successful: false,
            error: {
              status: -1,
              code: ApiErrors.Code.Unknown,
              messages: [`Logged in user is not an admin!`],
            },
          }
        }
      } else {
        console.error(response)
        return {
          successful: false,
          error: response,
        }
      }
    },
  },
})

export { useUserStore, LoginActionResponse }
