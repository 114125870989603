import axios, { AxiosResponse } from 'axios'
import { ApiErrors, ApiModels } from './types'

const serverEndpoint = import.meta.env.VITE_API_ENDPOINT

const http = axios.create({
  baseURL: serverEndpoint,
})

type HttpSuccessResult<T> = {
  status: number
  payload: T
}

function mapResponseToSuccessResult<T>(response: AxiosResponse<T>): HttpSuccessResult<T> {
  return {
    status: response.status,
    payload: response.data,
  }
}

function parseError(error: any): ApiErrors.ErrorModel {
  try {
    if (axios.isAxiosError(error)) {
      const responseData = error.response!.data as {
        code: ApiErrors.Code
        messages: string[]
      }

      return {
        status: error.response?.status ?? -1,
        code: responseData.code,
        messages: responseData.messages,
      }
    } else {
      throw error
    }
  } catch (exception) {
    console.error(exception)
    return {
      status: -1,
      code: ApiErrors.Code.Unknown,
      messages: ['Unknown error, check logs for details!'],
    }
  }
}

const QweebiApi = {
  authorize: function (accessToken: string) {
    http.defaults.headers.common['Authorization'] = accessToken
  },

  loginUser: async function (email: string, password: string) {
    return await http
      .post('v1.1/auth/users/login', {
        email: email,
        password: password,
      })
      .then(mapResponseToSuccessResult<ApiModels.LoginResponse>)
      .catch(parseError)
  },

  isSuccess: function <T>(response: ApiErrors.ErrorModel | HttpSuccessResult<T>): response is HttpSuccessResult<T> {
    return (response as HttpSuccessResult<T>).payload !== undefined
  },

  isError: function (response: ApiErrors.ErrorModel | HttpSuccessResult<any>): response is ApiErrors.ErrorModel {
    return (response as ApiErrors.ErrorModel).code !== undefined
  },
}

export { QweebiApi, HttpSuccessResult }
